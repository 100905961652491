<template>
  <section class="wrap">
    <div class="content">
      <!-- 培训包信息 -->
      <h3>
        {{ $t('package.package_infor') }}
        <span style="color: #999;font-size: 14px;margin-left: 10px;"><span style="color:red">*</span> 
        {{ $t('package.pay_tip') }}</span></h3>
        <!-- 支付成功后，您将收到一封激活码邮件，也可通过单位管理查看激活码 -->
      <div class="td">
        <div class="item">{{ $t('package.table.cover') }}</div>
        <!-- 封面 -->
        <div class="item">{{ $t('package.table.name') }}</div>
        <!-- 培训包名 -->
        <div class="item">{{ $t('package.table.quantity') }}</div>
        <!-- 数量 -->
        <div class="item">{{ $t('package.table.price') }}</div>
        <!-- 价格 -->
        <div class="item">{{ $t('package.table.validity_period') }}</div>
        <!-- 有效期 -->
      </div>
      <div class="tr" v-if="projectData">
        <div class="item">
          <img class="cover" :src="projectData.coverImg" alt="" />
        </div>
        <div class="item">
          <div class="name">{{ projectData.taskName }}</div>
        </div>
        <div class="item">
          <a-input-number
            id="inputNumber"
            v-model:value="number"
            :precision="0"
            :min="projectData.minPays"
          />
        </div>
        <div class="item">
          <div class="price">
            {{ calculate.multiply(projectData.price2, number) }}
          </div>
        </div>
        <div class="item">
          {{ projectData.effective }}
          <template v-if="projectData.dateType == 1">{{ $t('Pub_Day') }}</template>
          <!-- 天 -->
          <template v-if="projectData.dateType == 2">{{ $t('order.month') }}</template>
          <!-- 个月 -->
          <template v-if="projectData.dateType == 3">{{ $t('CM_Year') }}</template>
          <!-- 年 -->
        </div>
      </div>
      <div class="btns">
        <div class="btn" @click="jump">{{ $t('package.scan_code_pay') }}</div>
        <!-- 扫码支付 -->
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import calculate from "@/utils/calculate";
import { packageDetail } from "@/api/package";
export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const taskId = Number(route.query.taskId);

    const projectData = ref(null);
    const number = ref(0);
    packageDetail(taskId).then((res) => {
      projectData.value = res.data;
      number.value = res.data.minPays;
    });

    const jump = () => {
      router.push({
        path: "./pay",
        query: {
          taskId,
          buyType: 2,
          minPays: number.value,
          price: calculate.multiply(projectData.value.price2, number.value),
        },
      });
    };

    return {
      calculate,
      projectData,
      number,
      jump,
    };
  },
};
</script>

<style lang="less" scoped>
.wrap {
  padding: 40px 0;
  .content {
    .mixinWrap(1080px);
    background-color: #fff;
    padding: 0 24px 24px;
    h3 {
      // line-height: 64px;
      padding: 20px 0;
      font-size: 18px;
      margin: 0;
    }
    .td {
      .mixinFlex(space-between; center);
      background-color: #eeeeee;
      line-height: 52px;
      padding: 0 24px;
      .item {
        width: 20%;
      }
    }
    .tr {
      .mixinFlex(space-between; center);
      border: 1px solid #eeeeee;
      border-top: 0;
      padding: 24px;
      .item {
        width: 20%;
        .cover {
          width: 160px;
          height: 90px;
        }
        .name {
          width: 80%;
          word-break: break-all;
        }
        .price {
          color: #e63450;
        }
      }
    }
    .btns {
      text-align: right;
      .btn {
        display: inline-block;
        width: 130px;
        height: 50px;
        font-size: 16px;
        color: #fff;
        background-color: #387ad1;
        text-align: center;
        line-height: 50px;
        border-radius: 2px;
        margin-top: 24px;
        cursor: pointer;
      }
    }
  }
}
</style>